import { computed, ref } from 'vue';
import { defineStore } from 'pinia';

export const useChatStore = defineStore('chatStore', () => {
    const user_detail = ref({});
    const toggle_chat = ref(false);
    const selection = ref(false);
    const chat_list = ref([]);

    const getCommonGroups = (user) => {
        let commonGroups = [];
        chat_list.value?.filter((chat) => {
            if (chat?.chatName !== '1:1') {
                chat.users.some((item) => item.uuid === user)
                    ? commonGroups.push(chat)
                    : null;
            }
        });
        return commonGroups;
    };
    return {
        user_detail,
        toggle_chat,
        selection,
        chat_list,
        getCommonGroups,
    };
});
