<template>
    <Transition name="slide-fade">
        <div :class="['toast', toastVariants]" v-show="closeToast">
            <div class="toast_text">
                <div class="toast_icon">
                    <fa :icon="['fal', icons]" class="mr-3 toast_mark"></fa>
                </div>
                <div class="toast_body">
                    <h5 class="text-sm font-medium leading-[normal] text-black-900 mb-1">{{ headingText }}</h5>
                    <p class="text-xs leading-[normal] text-gray-500">{{ subText }}</p>
                </div>
            </div>
            <div class="toast_buttons">
<!--                <app-button variant="btn-white !px-3 !py-2.5 !h-8 !flex !items-center !mr-4" @btnClick="undoClick" :class="[undoBtn===false ? 'invisible' : '']">
                    <fa :icon="['far', 'undo']" class="text-sm mr-1 text-dark-100"></fa>
                    <span class="text-dark-100 text-sm">Undo</span>
                </app-button>-->
                <button class="close" @click="closeToastMethod">
                    <fa :icon="['fal', 'times']" class="text-sm text-gray-300"></fa>
                </button>
            </div>
        </div>
    </Transition>
</template>

<script setup>
import { computed, ref } from 'vue';
import AppButton from '@/components/AppButton.vue';
const emit = defineEmits(['undoClick']);
const props = defineProps({
    toastType: {
        type: String,
        default: 'info',
    },
    headingText: {
        type: String,
        default: '',
    },
    subText: {
        type: String,
        default: '',
    },
    undoBtn: {
        type: Boolean,
        default: false,
    },
});

const toastVariants = computed(() => {
    if (props.toastType === 'generic') {
        return 'generic';
    } else if (props.toastType === 'warning') {
        return 'warning';
    } else if (props.toastType === 'error') {
        return 'error';
    } else if (props.toastType === 'info') {
        return 'info';
    } else if (props.toastType === 'success') {
        return 'success';
    }
});
const icons = computed(() => {
    if (props.toastType === 'generic') {
        return '';
    } else if (props.toastType === 'warning') {
        return 'triangle-exclamation';
    } else if (props.toastType === 'error') {
        return 'circle-exclamation';
    } else if (props.toastType === 'info') {
        return 'circle-exclamation';
    } else if (props.toastType === 'success') {
        return 'check';
    }
});
const closeToast = ref(true);
const undoClick = () =>{
    emit('undoClick');
}
const closeToastMethod = () => {
    console.log(closeToast.value,'closeToastMethod');
    closeToast.value = false;
}
</script>

<style scoped>
.toast{
    @apply bg-white rounded-lg border-l-2 shadow-widget py-2.5 px-4 flex items-center self-stretch justify-between mt-2 h-[4.4375rem];
}
.toast.generic{
    border-color: transparent;
}
.toast.success{
    border-color: #00EBB4;
}
.toast.info{
    border-color: #00B0FC;
}
.toast.error{
    border-color: #FA0;
}
.toast.warning{
    border-color: #FF4650;
}


.toast.success .toast_mark{
    color: #00EBB4;
}
.toast.info .toast_mark{
    color: #00B0FC;
}
.toast.error .toast_mark{
    color: #FA0;
}
.toast.warning .toast_mark{
    color: #FF4650;
}

.toast_text{
    @apply flex items-center;
}
.toast_buttons{
    @apply flex items-center;
}
.toast_icon{
    @apply text-lg
}
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-in-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from{
    transform: translateX(0);
  opacity: 1;
}
.slide-fade-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
</style>
