import { computed, ref } from 'vue';
import { defineStore } from 'pinia';

export const useAuthStore = defineStore(
    'authStore',
    () => {
        const authUser = ref({});
        const mod = ref('contacts');
        const userSignupEmail = ref('');
        const userTimer = ref(0);
        const userResetEmail = ref('');
        const confirmationState = ref('');
        const secretKey = ref('');
        const userDetail =ref({});
        // Get auth user
        const getAuthUser = computed(() => authUser.value);
        const getMod = computed(() => mod.value);
        const getUserSignupEmail = computed(() => userSignupEmail.value);
        const getUserResetEmail = computed(() => userResetEmail.value);
        const getUserTimer = computed(() => userTimer.value);
        const getConfirmationState = computed(() => confirmationState.value);
        const getSecretKey = computed(() => secretKey.value);
        // Set logged in state
        const setMod = (value) => {
            mod.value = value;
        };
        const setAuthUser = (value) => {
            authUser.value = value;
        };
        const setUser = (value) => {
            userDetail.value = value;
        };
        const setUserSignupEmail = (value) => {
            userSignupEmail.value = value;
        };
        const setUserResetEmail = (value) => {
            userResetEmail.value = value;
        };
        const setUserTimer = (value) => {
            userTimer.value = value;
        };
        const setConfirmationState = (value) => {
            confirmationState.value = value;
        };
        const setSecretKey = (value) => {
            secretKey.value = value;
        };

        return {
            authUser,
            getMod,
            getAuthUser,
            setMod,
            setAuthUser,
            userSignupEmail,
            getUserSignupEmail,
            setUserSignupEmail,
            userResetEmail,
            getUserResetEmail,
            setUserResetEmail,
            confirmationState,
            getConfirmationState,
            setConfirmationState,
            userTimer,
            getUserTimer,
            setUserTimer,
            secretKey,
            getSecretKey,
            setSecretKey,
            setUser,
            userDetail
        };
    },
    {
        persist: true,
    }
);
