import {ref } from "vue";
import { defineStore } from "pinia";
export const useAccountStore = defineStore("accountStore", () => {
        const authenticate_data = ref();
        const clientAccountVerification = ref(false);
        const depositAccountVerification = ref(false);
        const currentAccountName = ref('')
        return { authenticate_data,clientAccountVerification , depositAccountVerification ,currentAccountName};
    },
    {
        persist: true
    });
