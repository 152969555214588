import { reactive } from 'vue';

export const chatroomStore = reactive({
    messages: [],
    chatRoom: {},
    activeChatContact:{},
});

export const messageStatus = reactive({
    isSend: false,
});

export const sendEmailNotification = reactive({
    isSend: false,
});

export const chatroomStatus = reactive({
    status: '',
});

export const filterCriteriaList = reactive({
    filterCriteria: {},
});
