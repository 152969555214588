
import Axios from "@/config/AxiosConfig";

export const getLedger = async (payload) => {
    const url = `account/get-ledger/${payload.id}/${payload.type}`;
    try {
        const { data } = await Axios.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const getMoneyIn = async () => {
    const url = `account/get-money-in-payments`;
    try {
        const { data } = await Axios.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const getMoneyOut = async () => {
    const url = `account/get-money-out-payments`;
    try {
        const { data } = await Axios.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const getFeeTypes = async () => {
    const url = `setting/fee-type/get_list`;
    try {
        const { data } = await Axios.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const addFeeType = async (payload) => {
    const url = `setting/fee-type/add`;
    try {
        const { data } = await Axios.post(url, payload);
        return data;
    } catch (e) {
        return e.response;
    }
};
export const updateFeeType = async (payload) => {
    const url = `setting/fee-type/update`;
    try {
        const { data } = await Axios.post(url, payload);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const activateFeeType = async (id) => {
    const url = `setting/fee-type/activate/${id}`;
    try {
        const { data } = await Axios.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};


export const bankConnections = async () => {
    const url = `moneyhub/connections`;
    try {
        const { data } = await Axios.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};


export const bankConnect = async (id, branch_id) => {
    const url = `moneyhub/connect/${id}/${branch_id}`;
    try {
        const { data } = await Axios.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const completeAuth = async (payload) => {
    const url = `moneyhub/complete-auth-request`;
    try {
        const { data } = await Axios.post(url, payload);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const pullStatements = async (date, page_name, branch_id) => {
    let url = ''
    if (date) {
        url = `moneyhub/accounts/${branch_id}/${page_name}/${date}`
    } else {
        url = `moneyhub/accounts/${branch_id}/${page_name}`
    }

    try {
        const { data } = await Axios.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const getUrl = async (payload) => {
    const url = `/moneyhub/get-url`
    try {
        const { data } = await Axios.post(url, payload);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const get = async (payload) => {
    const url = `/moneyhub/get-url`
    try {
        const { data } = await Axios.post(url, payload);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const patchData = async (payload) => {
    const url = `/moneyhub/get-patch-request-data`
    try {
        const { data } = await Axios.post(url, payload);
        return data;
    } catch (e) {
        return e.response;
    }
};
export const checkUserConnection = async (branch_id, account_type) => {
    let url;
    if (account_type) { url = `/moneyhub/check-user-connection/${branch_id}/${account_type}` }
    else { url = `/moneyhub/check-user-connection/${branch_id}` }
    try {
        const { data } = await Axios.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const getAccounts = async (account_type, branch_id) => {
    const url = `/moneyhub/check-multiple-accounts/${account_type}/${branch_id}`
    try {
        const { data } = await Axios.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const updateMoneyHubConnection = async () => {
    const url = `/moneyhub/update-moneyhub-connection`
    try {
        const { data } = await Axios.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const DisconnectUsers = async (payload) => {
    const url = `/moneyhub/delete-moneyhub-user`
    try {
        const { data } = await Axios.post(url, payload);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const MakeSinglePayment = async (id) => {
    const url = `/moneyhub/create-payment-request/${id}`
    try {
        const { data } = await Axios.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const confirmPayment = async (payload) => {
    const url = `/moneyhub/get-payment-status`
    try {
        const { data } = await Axios.post(url, payload);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const getCountTotal = async (payload) => {
    const url = `/account/get-payout-entries-data`
    try {
        const { data } = await Axios.post(url, payload);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const getfloats = async (id) => {
    const url = `/account/get-float/${id}`
    try {
        const { data } = await Axios.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const createTransfer = async (payload) => {
    const url = `/account/create-transfer`
    try {
        const { data } = await Axios.post(url, payload);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const GetUnreconcileMatches = async (payload) => {
    const url = `/account/get-ledger?${payload}`
    try {
        const { data } = await Axios.get(url, payload);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const ReconcileTrans = async (payload) => {
    const url = `/account/reconcile-trans`
    try {
        const { data } = await Axios.post(url, payload);
        return data;
    } catch (e) {
        return e.response;
    }
};


export const convertDeductRent = async (payload) => {
    const url = `/account/convert-deduct-rent-to-request-payment/${payload}`
    try {
        const { data } = await Axios.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};