// Imports
import axios from "axios";
import Cookie from "js-cookie";

export default {
    async getCookie() {
        let token = Cookie.get("XSRF-TOKEN");

        if (token) {
            return new Promise((resolve) => {
                resolve(token);
            });
        }

        return axios.get(`${import.meta.env.VITE_APP_AUTH_BASE_URL}/sanctum/csrf-cookie`, { withCredentials: true });
    },

    removeCookie() {
        Cookie.remove("XSRF-TOKEN", {
            path: "",
            domain: import.meta.env.VITE_APP_BASE_DOMAIN,
        });
    },
};
