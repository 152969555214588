// directives/rendered.js
export default {
    mounted(el, binding) {
        // When the element is mounted, call the function
        if (typeof binding.value === "function") {
            binding.value();
        }
    },
    updated(el, binding) {
        // When the element is updated, call the function
        if (typeof binding.value === "function") {
            binding.value();
        }
    },
};
