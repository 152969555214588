export default [
    {
        path: "/diary",
        name: "diary-page",
        query: {
            date: "",
            id: "",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/views/diary/DiaryView.vue"),
        meta: { isWorkspace: true },
    },
];
