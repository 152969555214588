<template>
    <app-modal :backdrop="false" v-if="hasRole(modalShowVal)" class="!bg-dark-100 !bg-opacity-30">
            <app-modal-content class="!bg-transparent !shadow-none">
                <app-modal-body classes="!p-6">
                    <app-spinner :bgColor="true">
                        <span class="text-sm font-medium text-white">
                            {{ content }}
                        </span>
                    </app-spinner>
                </app-modal-body>
                <!-- <div class="flex fixed right-6 bottom-6">
                    <app-button
                        type="button"
                        class="btn blue-outline-bg"
                        variant="mr-2"
                        @btnClick="toggleModal"
                    >
                        Cancel
                    </app-button>
                </div> -->
            </app-modal-content>
        <app-modal-backdrop @onHideModal="emit('onCloseModal')" />
    </app-modal>
</template>

<script setup>
import { ref } from 'vue';
// Components Imports
import AppModal from '@/components/modal/AppModal.vue';
import AppModalBackdrop from '@/components/modal/AppModalBackdrop.vue';
import AppModalBody from '@/components/modal/AppModalBody.vue';
import AppModalHeader from '@/components/modal/AppModalHeader.vue';
import AppModalContent from '@/components/modal/AppModalContent.vue';
import AppModalDialog from '@/components/modal/AppModalDialog.vue';
import AppModalFooter from '@/components/modal/AppModalFooter.vue';
import AppSpinner from '@/components/AppSpinner.vue';
import AppButton from '@/components/AppButton.vue';
// Composables Imports
import useToggleModal from '@/composables/useToggleModal';

const emit = defineEmits(['onCloseModal', 'onSubmission']);

const props = defineProps({
    modalShowVal: {
        type: String,
        default: '',
        required: true,
    },
    content: {
        type: String,
        default: '',
        required: true,
    },
});

// Modal composable
const { openModal, hasRole, toggleModal } = useToggleModal();

function onToggleModal(
    role,
    hidden = 'hidden',
    padding = '15px',
    fixed = 'absolute'
) {
    openModal(role, hidden, padding, fixed);
}
const showFormModal = (name) => {
    onToggleModal(name);
};
const closeFormModal = (name) => {
    console.log(name, 'colse');
    toggleModal(name);
};
defineExpose({
    showFormModal,
    closeFormModal,
});
</script>

<style scoped>
/* :deep(.modal_content){
    @apply !bg-transparent !shadow-none;
} */
</style>
