<template>
    <div
        class="modal"
        :class="[
            transparentModal ? 'bg-dark-100 bg-opacity-70' : '',
            grid
                ? 'modal-grid grid-cols-12 vz_gap vz_px xs:!px-0'
                : 'modal-flex',
        ]"
    >
        <!-- <Transition name="fade"> -->
        <app-modal-dialog
            :transparentModalDialog="transparentModalDialog"
            :grid="grid"
            :size="size"
            :class="customWidth"
        >
            <slot></slot>
        </app-modal-dialog>
        <!-- </Transition> -->
        <app-modal-backdrop class="inner-animation" v-if="backdrop" />
    </div>
</template>

<script setup>
import { onBeforeUnmount } from 'vue';
import AppModalBackdrop from '@/components/modal/AppModalBackdrop.vue';
import AppModalDialog from '@/components/modal/AppModalDialog.vue';
const props = defineProps({
    transparentModal: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: 'default',
    },
    transparentModalDialog: {
        type: Boolean,
        default: false,
    },
    backdrop: {
        type: Boolean,
        default: true,
    },
    customWidth: {
        type: [String, Number],
        default: null,
    },
    grid: {
        type: Boolean,
        default: false,
    },
});
onBeforeUnmount((event) => {
    // console.log(event,'modal is unmounting')
});
</script>

<style scoped>
.modal {
    @apply fixed top-[50px] md:top-0 md:bottom-0 xs:bottom-0 xs:top-auto left-0 z-[99] items-center content-center justify-center m-auto overflow-x-hidden overflow-y-auto md:h-screen w-screen transition-all;
}
.modal.modal-grid {
    @apply grid;
}
.modal.modal-flex {
    @apply flex;
}

@keyframes fade {
    0% {
        opacity: 0;
        transition: opacity 0.5s ease;
    }

    100% {
        opacity: 1;
        transition: opacity 0.5s ease;
    }
}

@keyframes slide-in {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0%);
    }
}

@keyframes slideOut {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(-100%);
    }
}

/* we will explain what these classes do next! */
/* .fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
} */
/* .fade-enter-active {
    animation: slide-in 1000ms;
}

.fade-leave-active {
    animation: slide-in 1000ms alternate-reverse;
} */

/* .fade-enter-from,
.fade-leave-to {
    opacity: 0;
} */

/* .slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
} */
</style>
