import { reactive, readonly } from 'vue';
const modal = reactive({
    role: [],
});

export default function useToggleModal() {
    // eslint-disable-next-line no-unused-vars
    const toggleModal = (role = '') => {
        modal.role.pop();
        document.body.style = '';
        document.body.classList.remove('scroll-end');
    };

    const openModal = (role = '', overflow = '') => {
        modal.role.push({ type: role, isOpen: true });
        document.body.style.overflow = overflow;
        if (document.body.scrollHeight >= document.body.clientHeight) {
            document.body.classList.add('scroll-end');
        }
    };

    const hasRole = (role = '') => {
        if (role === '') return false;
        const findRole = modal.role.find((currentRole) =>
            currentRole.type === '' ? null : currentRole.type === role
        );

        if (findRole === undefined) return false;
        return findRole.type === role && findRole.isOpen === true
            ? true
            : false;
    };
    return {
        state: readonly(modal),
        toggleModal,
        openModal,
        hasRole,
    };
    // we create our gloabl state modal and makes state reactive(), so that each time we change value of state it will update state to component too, meaning component will get new state whenever this global state object is changed.
}
