import { useAccountStore } from '@/stores/appAccountsStore';

export default [
    {
        path: '/accounts',
        name: 'accounts',
        // redirect: {name:'banks'} ,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/accounts/Account.vue'),
        meta: { isWorkspace: true },
        children: [
            {
                path: 'connect_bank',
                name: 'connect_bank',
                // redirect: {name:'banks'} ,
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/accounts/MoneyHub.vue'),
                meta: { isWorkspace: true },
            //     children:[
                  
            // ]
            },
            {
                path: 'banks',
                name: 'banks',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/accounts/ConnectClientAccount.vue'),
                beforeEnter: (to, from, next) => {
                    const accountStore = useAccountStore()
                    
                    if(!accountStore.clientAccountVerification && accountStore.currentAccountName ==='client')
                    {
                        next({name:'client-account'})
                    } else if(!accountStore.depositAccountVerification && accountStore.currentAccountName ==='deposit')
                    {
                        next({name:'deposit-account'})
                    }
                    else{
                        next()
                    }
                  },
                meta: { isWorkspace: true },
            },

            {
                path: 'deposit_account_bank',
                name: 'deposit_account_bank',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/accounts/ConnectdepositAccount.vue'),
                beforeEnter: (to, from, next) => {
                    const accountStore = useAccountStore()
                    if(!accountStore.clientAccountVerification && accountStore.currentAccountName ==='client')
                    {
                        next({name:'client-account'})
                    } else if(!accountStore.depositAccountVerification && accountStore.currentAccountName ==='deposit'){
                        next({name:'deposit-account'})
                    }
                    else{
                        next()
                    }
                  },
                meta: { isWorkspace: true },
            },
            {
            path: 'bank_details/:name/:bank_id',
            name: 'bank-details',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('@/views/accounts/ConnectBankDetails.vue'),
            meta: { isWorkspace: true },
            beforeEnter: (to, from, next) => {
                const accountStore = useAccountStore()
                
                if(!accountStore.clientAccountVerification && accountStore.currentAccountName ==='client')
                {
                    next({name:'client-account'})
                } else if(!accountStore.depositAccountVerification && accountStore.currentAccountName ==='deposit')
                {
                    next({name:'deposit-account'})
                }
                else{
                    next()
                }
              },
        },
        {
            path: 'authenticate_moneyhub',
            name: 'authenticate_moneyhub',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('@/views/accounts/AuthenticateMoneyhub.vue'),
            meta: { isWorkspace: true },
            beforeEnter: (to, from, next) => {
                const accountStore = useAccountStore()
                
                if(!accountStore.clientAccountVerification && accountStore.currentAccountName ==='client')
                {
                    next({name:'client-account'})
                } else if(!accountStore.depositAccountVerification && accountStore.currentAccountName ==='deposit')
                {
                    next({name:'deposit-account'})
                }
                else{
                    next()
                }
              },
        },
        {
            path: 'HSBC',
            name: 'HSBC',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('@/views/accounts/HSBC.vue'),
            meta: { isWorkspace: true },
            beforeEnter: (to, from, next) => {
                const accountStore = useAccountStore()
                
                if(!accountStore.clientAccountVerification && accountStore.currentAccountName ==='client')
                {
                    next({name:'client-account'})
                } else if(!accountStore.depositAccountVerification && accountStore.currentAccountName ==='deposit')
                {
                    next({name:'deposit-account'})
                }
                else{
                    next()
                }
              },
        },
        {
            path: 'statement',
            name: 'statement',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('@/views/accounts/Statement.vue'),
            meta: { isWorkspace: true },
            beforeEnter: (to, from, next) => {
                const accountStore = useAccountStore()
                
                if(!accountStore.clientAccountVerification && accountStore.currentAccountName ==='client')
                {
                    next({name:'client-account'})
                } else if(!accountStore.depositAccountVerification && accountStore.currentAccountName ==='deposit')
                {
                    next({name:'deposit-account'})
                }
                else{
                    next()
                }
              },
        },
        {
            path: 'bank_link',
            name: 'bank_link',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('@/views/accounts/BankLink.vue'),
            meta: { isWorkspace: true },
            beforeEnter: (to, from, next) => {
                const accountStore = useAccountStore()
                
                if(!accountStore.clientAccountVerification && accountStore.currentAccountName ==='client')
                {
                    next({name:'client-account'})
                } else if(!accountStore.depositAccountVerification && accountStore.currentAccountName ==='deposit')
                {
                    next({name:'deposit-account'})
                }
                else{
                    next()
                }
              },
        },
            {
                path: 'client_account/:date?',
                name: 'client-account',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/accounts/ClientAccount.vue'),
                meta: { isWorkspace: true },
                beforeEnter: (to, from, next) => {
                    const accountStore = useAccountStore()
                    if(accountStore.clientAccountVerification)
                    {
                        next({name:'banks'})
                    }else{
                        next()
                    }
                  },
            },
            {
                path: 'deposit_account',
                name: 'deposit-account',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/accounts/DepositAccount.vue'),
                meta: { isWorkspace: true },
                beforeEnter: (to, from, next) => {
                    const accountStore = useAccountStore()
                    if(accountStore.depositAccountVerification)
                    {
                        next({name:'deposit_account_bank'})
                    }else{
                        next()
                    }
                  },

            },
            {
                path: 'deposit_custodial',
                name: 'deposit-custodial',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/accounts/DepositCustodial.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'money_in',
                name: 'money-in',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/accounts/MoneyIn.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'money_out',
                name: 'money-out',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/accounts/MoneyOut.vue'),
                meta: { isWorkspace: true },
            },
            {
                path: 'floats',
                name: 'floats',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import('@/views/accounts/DepositsFloats.vue'),
                meta: { isWorkspace: true },
            },
           
        ],
    },
];
