export default [
    {
        path: "/activities",
        name: "activities",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/views/activities/ActivitiesView.vue"),
        meta: { isWorkspace: true }
    }
]