<template>
    <div
        :class="[
            'modal_dialog',
            sizes,
            transparentModalDialog ? '!h-auto backDialog' : '',
            grid ? 'modal_dialog_grid' : 'modal_dialog_flex',
            sizes == 'modal_small'
                ? 'lg:col-span-4 lg:col-start-5 sm:col-span-8 sm:col-start-3 col-span-12'
                : sizes == 'modal_large'
                  ? 'md:col-span-8 md:col-start-3 col-span-12'
                  : '',
        ]"
    >
        <slot></slot>
    </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    size: {
        type: String,
        default: 'default',
    },
    transparentModalDialog: {
        type: Boolean,
        default: false,
    },
    grid: {
        type: Boolean,
        default: false,
    },
});

const sizes = computed(() => {
    if (props.size === 'small') {
        return 'modal_small';
    } else if (props.size === 'medium') {
        return 'modal_medium';
    } else if (props.size === 'normal') {
        return 'modal_normal';
    } else if (props.size === 'xsmall') {
        return 'modal_xsmall';
    } else if (props.size === 'large') {
        return 'modal_large';
    } else {
        return 'modal_default';
    }
});
</script>

<style scoped>
.modal_dialog {
    /* @apply sm:h-[calc(100vh-3rem)] z-50 flex items-center; */
    @apply sm:h-[calc(100vh-3rem)] z-50 flex items-center;
}
.modal_dialog_flex {
    @apply flex;
}
.modal_dialog_grid {
    @apply grid;
}

.modal_default {
    @apply w-screen sm:max-w-[77.5rem] m-auto sm:w-[calc(100%-9.25%)];
    /* @apply w-screen sm:max-w-[77.5rem] m-auto; */
}
.modal_large {
    /* @apply w-full; */
    @apply w-screen sm:max-w-[69.2rem] m-auto sm:w-[calc(100%-9.25%)];
    /* @apply w-screen sm:max-w-[69.2rem] m-auto; */
}
.modal_medium {
    /* @apply sm:w-[calc(100%-9.25%)] xl:max-w-[37.313rem] lg:max-w-[28rem] sm:max-w-[28.5rem] max-w-[28.5rem] xs:max-w-[100%] m-auto xs:w-full; */
    @apply sm:w-[calc(100%-9.25%)] xl:max-w-[37.313rem] lg:max-w-[28rem] sm:max-w-[28.5rem] max-w-[28.5rem] xs:max-w-[100%] m-auto xs:w-full;
    /* @apply xl:max-w-[37.313rem] lg:max-w-[28rem] sm:max-w-[28.5rem] max-w-[28.5rem] xs:max-w-[100%] m-auto xs:w-full; */
}

/* .modal_small {
    @apply w-[calc(100%-33px)] xs:w-full xs:block sm:max-w-[25rem] m-auto sm:min-h-[21.125rem];
} */
.modal_normal {
    @apply w-screen sm:max-w-[40rem] sm:w-[calc(100%-9.25%)] m-auto xs:w-full;
}
.modal_xsmall {
    @apply md:h-[calc(100vh-37rem)] sm:w-[calc(100%-9.25%)] w-[360px] sm:max-w-[37.313rem] m-auto;
}
.backDialog {
    width: max-content !important;
}
</style>
