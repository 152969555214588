<template>
    <div id="app">
        <div :class="`vue3-progress ${appStore.appData.routerLoading === 'start' ?'router-loading': appStore.appData.routerLoading === 'fade'?'router-fade':'router-finish'}`" ></div>
        <!-- Dynamic Header Component -->
        <div class="main-layout">
            <!-- Side Header Component -->
            <app-nav
                v-if="getClientPortalUrl"
                :class="[
                    headerStore.header.routeName === 'Inbox' &&
                    ChatStore.toggle_chat
                        ? 'modile_view_hide'
                        : '',
                ]"
            />

            <!-- Router View -->
            <div
                class="app_container app_detail_container"
                :class="
                    getClientPortalUrl ? 'bg-gray-100 sm:pl-16' : '!bg-white'
                "
            >
                <router-view />
            </div>
        </div>
    </div>

    <Teleport to="body">
        <app-toast-container>
            <template v-for="(s, index) in toastsSuccess" :key="index">
                <app-toast
                    v-if="s.show"
                    :headingText="s.title"
                    :subText="s.message"
                    toastType="success"
                    :undoBtn="true"
                />
            </template>
            <template v-for="(w, index1) in toastsWarning" :key="index1">
                <app-toast
                    v-if="w.show"
                    :headingText="w.title"
                    :subText="w.message"
                    toastType="warning"
                />
            </template>
            <template v-for="(e, index2) in toastsError" :key="index2">
                <app-toast
                    v-if="e.show"
                    :headingText="e.title"
                    :subText="e.message"
                    toastType="error"
                />
            </template>
            <template v-for="(i, index3) in toastsInfo" :key="index3">
                <app-toast
                    v-if="i.show"
                    :headingText="i.title"
                    :subText="i.message"
                    toastType="info"
                />
            </template>
            <template v-for="(g, index4) in toastsGeneric" :key="index4">
                <app-toast
                    v-if="g.show"
                    :headingText="g.title"
                    :subText="g.message"
                    toastType="generic"
                />
            </template>
        </app-toast-container>
    </Teleport>

    <Teleport to="body">
        <spinner-modal
            modalShowVal="spinnerModalToShow"
            heading="Fees"
            ref="spinnerModal"
            :content="LoaderContent"
        />
    </Teleport>
</template>

<script setup>
import AppNav from '@/components/navigation/AppNav.vue';
import { onBeforeMount, onMounted, computed, ref, onUpdated, watch } from 'vue';

// Vue router imports
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import { useTenantStore } from '@/stores/tenant';
import { useHeaderStore } from '@/stores/headerStore.js';
// I18n imports
import { useI18n } from 'vue-i18n';

// Components imports
import AppTopbar from '@/components/topbar/AppTopbar.vue';
import AppTopbarHeading from '@/components/topbar/AppTopbarHeading.vue';
import AppTopbarRight from '@/components/topbar/AppTopbarRight.vue';
import AppTopbarLeft from '@/components/topbar/AppTopbarLeft.vue';
import AppTopbarSearch from '@/components/topbar/AppTopbarSearch.vue';
import AppTopbarNotification from '@/components/topbar/AppTopbarNotification.vue';
import AppTopbarUser from '@/components/topbar/AppTopbarUser.vue';
import AppOffcanvas from '@/components/useroffcanvas/AppOffcanvas.vue';
import AppOffcanvasBody from '@/components/useroffcanvas/AppOffcanvasBody.vue';
import AppOffcanvasClose from '@/components/useroffcanvas/AppOffcanvasClose.vue';
import AppOffcanvasHeader from '@/components/useroffcanvas/AppOffcanvasHeader.vue';
import AppOffcanvasAccounts from '@/components/useroffcanvas/AppOffcanvasAccounts.vue';
import AppOffcanvasList from '@/components/useroffcanvas/AppOffcanvasList.vue';
import AppOffcanvasBackdrop from '@/components/useroffcanvas/AppOffcanvasBackdrop.vue';
import AppSwitch from '@/components/forms/AppSwitch.vue';
import AppToastContainer from '@/components/toasts/AppToastContainer.vue';
import AppToast from '@/components/toasts/AppToast.vue';
import UserModal from '@/components/settings/modals/UserModal.vue';
import AppTopbarCenter from '@/components/topbar/AppTopbarCenter.vue';
import AppSmartSearch from '@/components/smartSearch/AppSmartSearch.vue';
import SpinnerModal from '@/components/modal/SpinnerModal.vue';
import AppButton from '@/components/AppButton.vue';
import AppButtonGroup from '@/components/buttongroup/AppButtonGroup.vue';
import AppDropdown from '@/components/dropdown/AppDropdown.vue';
import AppDropdownList from '@/components/dropdown/AppDropdownList.vue';
import AppLabel from '@/components/AppLabel.vue';
import Tabs from '@/components/tabber/Tabs.vue';
import NewMessage from '@/components/settings/inbox/inbox-component/NewMessage.vue';
import ContactNameIcon from '@/components/contacts/ContactNameIcon.vue';
import { getTimelineDropDownList } from '@/api/TimelineServices';
import ContactBoardHeader from '@/components/header/contact/ContactBoardHeader.vue';
import PropertyBoardHeader from '@/components/header/property/PropertyBoardHeader.vue';
import { useChatStore } from '@/stores/chatStore';
import { getModPermissionsData } from '@/api/UserServices';

// Spinner Loader import
import { SpinnerLoader } from '@/composables/SpinnerLoader';
const ChatStore = useChatStore();
const { spinnerModal, LoaderContent } = SpinnerLoader();
// Composables imports
import {
    getUserImage,
    getUserFullName,
    getPreviousNextRoute,
    useScreenSize,
} from '@/composables/globals';

// API services imports
import Csrf from '@/api/Csrf';

//Board store imports
import { useBoardStore } from '@/stores/boardStore';
import { Toast } from "@/composables/Toast";
import { useAppStore } from './stores/appStore';

const boardStore = useBoardStore();
const { t, locale } = useI18n();
const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();
const tenantStore = useTenantStore();
const headerStore = useHeaderStore();
const isDesktop = useScreenSize();
const appStore = useAppStore()
const toggleName = ref();

const { toastsSuccess, toastsWarning, toastsError, toastsInfo, toastsGeneric } =
    Toast();

onBeforeMount(() => {
    if (tenantStore && tenantStore.tenant.appearance === 'dark') {
        document.documentElement.classList.add('dark');
    }

    if (tenantStore && tenantStore.tenant.lang) {
        locale.value = tenantStore.tenant.lang === 'en-GB' ? 'en' : 'fr';
    }
});

onMounted(async () => {
    // emitter.on('diaryEventDropdownList', fetch_dorpdown_list);
    if (Object.keys(authStore.authUser).length !== 0) {
        Csrf.getCookie();
        getPreviousNextRoute();
        // fetch_dorpdown_list();
        await router.isReady();
        toggleName.value = currentRouteName.value;
        headerStore.setBoardCount(0);
        headerStore.setSelectedItemStatus(false);
    }
});

const pageHeading = computed(() => {
    const routeName = router.currentRoute.value.name;
    headerStore.setHeaderRouteName(routeName);
    switch (route.name) {
        case 'dashboard':
            return 'Dashboard';
        case 'activities':
            return 'Activities';
        case 'Inbox':
            return 'Inbox';
        case 'timeline':
            return 'Timeline';
        case 'diary-page':
            return 'Diary';
        case 'contact-boards':
            return 'Contact Boards';
        case 'property-boards':
            return 'Property Boards';
        case 'client-account':
            return 'Accounts';
        case 'deposit-account':
            return 'Accounts';
        case 'deposit-custodial':
            return 'Accounts';
        case 'money-in':
            return 'Accounts';
        case 'money-out':
            return 'Accounts';
        case 'floats':
            return 'Accounts';
        default:
            return 'Page name';
    }
});

watch(pageHeading, () => {
    boardStore.setCurrentBoard(pageHeading.value);
});

const getClientPortalUrl = computed(() => {
    return tenantStore && tenantStore.getTenant?.client_portal_url;
});

const currentRouteName = computed(() => {
    return router.currentRoute.value.name;
});

const changeView = (value) => {
    toggleName.value = value.name;
    if (value.name === 'conversation') {
        router.push('conversation');
        toggleName.value = 'conversation';
    } else {
        router.push('timeline');
    }
};

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
    margin: 0;
    padding: 0;
}

html,
body {
    font-family: 'Inter', sans-serif;
    @apply transition-all ease-linear relative;
    -webkit-overflow-scrolling: touch;
}

body {
    line-height: 1.5;
}
/* Basic styling to arrange the layout */
.main-layout {
    display: flex;
}

.side-header {
    width: 200px;
}

/* Main Container */
.app_container {
    @apply w-full bg-gray-100 xs:!bg-transparent dark:bg-black-900 !h-screen  pb-[5rem] sm:pb-0 xs:overflow-y-auto;
}

@media only screen and (max-width: 1270px) {
    .pad_view_show {
        @apply !block;
    }
    .pad_view_flex {
        @apply flex;
    }
    .pad_view_hidden {
        @apply hidden;
    }
}

@media only screen and (max-width: 574px) {
    .mobile_view_flex {
        @apply !flex;
    }
    .modile_view_hide {
        @apply hidden;
    }
    .modile_view_hidden {
        @apply hidden;
    }
}

.vue3-progress {
  /* Default Rainbow Color */
  /* Default Rainbow Color */
  height: 3px;

  background: #a307ba;
  background: #27c4f5 -webkit-gradient(linear, left top, right top, from(#27c4f5), color-stop(#a307ba), color-stop(#fd8d32), color-stop(#70c050), to(#27c4f5));
  background: #27c4f5 -webkit-linear-gradient(left, #27c4f5, #a307ba, #fd8d32, #70c050, #27c4f5);
  background: #27c4f5
    linear-gradient(to right, #27c4f5, #a307ba, #fd8d32, #70c050, #27c4f5);

  -webkit-animation: 2s linear infinite rainbow, 0.5s ease-out enter;
  animation: 2s linear infinite rainbow, 0.5s ease-out enter;

  /* Default Rainbow Color */
  /* Default Rainbow Color */

  -webkit-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;

  -webkit-transform-origin: left;
  transform-origin: left;

  background-size: 500%;

  z-index: 999999;
  position: fixed;
  display: block;
  width: 100%;
  opacity: 1;
  left: 0;
  top: 0;
}

@-webkit-keyframes rainbow {
  0% {
    background-position: 0% 0;
  }
  to {
    background-position: 125% 0;
  }
}

@keyframes rainbow {
  0% {
    background-position: 0% 0;
  }
  to {
    background-position: 125% 0;
  }
}

@-webkit-keyframes enter {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes enter {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.router-fade {
    opacity: 0;
}

.router-finish {
    display: none;
}

.router-loading {
    opacity: 1;
    display: block;
}
</style>
